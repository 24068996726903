<script lang="ts">
    import { afterNavigate, goto } from '$app/navigation';
    import { routes } from '$lib/routes';

    let value;

    function clearSearch() {
        value = '';
    }

    afterNavigate(({ from, to }) => {
        if (
            from?.pathname.startsWith(routes.search_root) &&
            !to?.pathname.startsWith(routes.search_root)
        ) {
            clearSearch();
        }
    });
</script>

<li class="flex flex-shrink flex-grow space-x-1 hover:text-blue">
    <label for="search" class="text-shadow-white dark:text-shadow-black cursor-pointer"
        >Search</label
    >
    <input
        id="search"
        name="search"
        type="search"
        class="min-w-10 w-full flex-shrink flex-grow border-b bg-transparent px-1"
        bind:value
        on:keypress={(event) => {
            if (event.key === 'Enter') {
                goto(routes.search(value));
            }
        }}
        placeholder=""
    />
</li>
